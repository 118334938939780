var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-2",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":_vm.closeOpenFilters}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplyed}}):_vm._e()],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.closeOpenFilters($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","label":"N° de expediente","clearable":"","disabled":_vm.nroExpedienteDisabled,"autocomplete":"off"},model:{value:(_vm.nroExpediente),callback:function ($$v) {_vm.nroExpediente=$$v},expression:"nroExpediente"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"hint":"formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","clearable":"","dense":"","disabled":_vm.fechasDisabled,"outlined":"","autocomplete":"off","rules":_vm.fechaHasta != null && _vm.nroExpediente == null
                            ? _vm.rules.validDate.concat(
                                _vm.rules.required,
                                new Date(
                                  _vm.parseDateToIso(_vm.fechaDesdeTextField)
                                ) <=
                                  new Date(
                                    _vm.parseDateToIso(_vm.fechaHastaTextField)
                                  ) || 'Formato incorrecto'
                              )
                            : []},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuDesde = false},"blur":function($event){[(_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeTextField))]},"change":function($event){[(_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeTextField))]}},model:{value:(_vm.fechaDesdeTextField),callback:function ($$v) {_vm.fechaDesdeTextField=$$v},expression:"fechaDesdeTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDesde),callback:function ($$v) {_vm.menuDesde=$$v},expression:"menuDesde"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaDesdeTextField = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","hint":"formato DD/MM/AAAA","clearable":"","dense":"","rules":_vm.fechaDesde != null && _vm.nroExpediente == null
                            ? _vm.rules.validDate.concat(
                                _vm.rules.required,
                                new Date(
                                  _vm.parseDateToIso(_vm.fechaDesdeTextField)
                                ) <=
                                  new Date(
                                    _vm.parseDateToIso(_vm.fechaHastaTextField)
                                  ) || 'Formato incorrecto'
                              )
                            : [],"outlined":"","autocomplete":"off","disabled":_vm.fechasDisabled},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuHasta = false},"blur":function($event){[(_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaTextField))]},"change":function($event){[(_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaTextField))]}},model:{value:(_vm.fechaHastaTextField),callback:function ($$v) {_vm.fechaHastaTextField=$$v},expression:"fechaHastaTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuHasta),callback:function ($$v) {_vm.menuHasta=$$v},expression:"menuHasta"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaHastaTextField = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1)],1),_c('v-row',{staticClass:"pr-4 pl-4",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12","align":"end"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","small":"","disabled":!_vm.isFormValid,"type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"logSincronizacionGeclisaId","items":_vm.resultsLogSincronizacionGeclisa,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true}])})],1)],1),_c('v-col',{staticClass:"to-right pt-0 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }