<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="closeOpenFilters"
              >
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="pt-0"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="closeOpenFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row class="pr-4">
                  <!-- Expediente -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="N° de expediente"
                      v-model="nroExpediente"
                      clearable
                      :disabled="nroExpedienteDisabled"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0">
                    <!-- Fecha desde -->
                    <v-menu
                      ref="menu"
                      v-model="menuDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeTextField"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="formato DD/MM/AAAA"
                          @keydown.tab="menuDesde = false"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            [(fechaDesde = parseDateToIso(fechaDesdeTextField))]
                          "
                          @change="
                            [(fechaDesde = parseDateToIso(fechaDesdeTextField))]
                          "
                          clearable
                          dense
                          :disabled="fechasDisabled"
                          outlined
                          autocomplete="off"
                          :rules="
                            fechaHasta != null && nroExpediente == null
                              ? rules.validDate.concat(
                                  rules.required,
                                  new Date(
                                    parseDateToIso(fechaDesdeTextField)
                                  ) <=
                                    new Date(
                                      parseDateToIso(fechaHastaTextField)
                                    ) || 'Formato incorrecto'
                                )
                              : []
                          "
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>

                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        scrollable
                        @change="fechaDesdeTextField = formatDate(fechaDesde)"
                        @input="menuDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0">
                    <!-- Fecha hasta -->
                    <v-menu
                      ref="menu2"
                      v-model="menuHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaTextField"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          @keydown.tab="menuHasta = false"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          hint="formato DD/MM/AAAA"
                          @blur="
                            [(fechaHasta = parseDateToIso(fechaHastaTextField))]
                          "
                          @change="
                            [(fechaHasta = parseDateToIso(fechaHastaTextField))]
                          "
                          clearable
                          dense
                          :rules="
                            fechaDesde != null && nroExpediente == null
                              ? rules.validDate.concat(
                                  rules.required,
                                  new Date(
                                    parseDateToIso(fechaDesdeTextField)
                                  ) <=
                                    new Date(
                                      parseDateToIso(fechaHastaTextField)
                                    ) || 'Formato incorrecto'
                                )
                              : []
                          "
                          outlined
                          autocomplete="off"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="fechasDisabled"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        scrollable
                        @change="fechaHastaTextField = formatDate(fechaHasta)"
                        @input="menuHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row class="pr-4 pl-4" justify="end">
                  <v-col cols="12" md="12" align="end" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            item-key="logSincronizacionGeclisaId"
            :items="resultsLogSincronizacionGeclisa"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-0 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "LogSincronizacionGeclisa",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    Ayuda
  },
  data: vm => ({
    title: "Log de sincronización con Geclisa",
    filtersApplyed: [],
    rules: rules,
    optionCode: enums.webSiteOptions.LOG_SINCRONIZACION_GECLISA,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    searchIcon: enums.icons.SEARCH,
    calendarIcon: enums.icons.CALENDAR,
    menuDesde: null,
    menuHasta: null,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    fechaDesde: null,
    fechaDesdeTextField: null,
    fechaHasta: null,
    fechaHastaTextField: null,
    search: "",
    showFilters: true,
    nroExpediente: null,
    isFormValid: false,
    fechasDisabled: false,
    nroExpedienteDisabled: false,
    resultsLogSincronizacionGeclisa: [],
    headers: [
      {
        text: "Opción",
        sortable: false,
        value: "opcion"
      },
      {
        text: "N° de expediente",
        sortable: false,
        value: "expedienteId"
      },
      {
        text: "Mensaje",
        sortable: false,
        value: "mensaje"
      },

      {
        text: "Resultado",
        sortable: false,
        value: "resultado"
      },
      {
        text: "Fecha de registro",
        sortable: false,
        value: "fechaRegistro"
      }
    ]
  }),
  watch: {
    nroExpediente() {
      if (this.nroExpediente != null) this.fechasDisabled = true;
      else this.fechasDisabled = false;
    },
    fechaDesde() {
      if (this.fechaDesde != null) this.nroExpedienteDisabled = true;
      else this.nroExpedienteDisabled = false;
    },
    fechaHasta() {
      if (this.fechaHasta != null) this.nroExpedienteDisabled = true;
      else this.nroExpedienteDisabled = false;
    }
  },
  methods: {
    ...mapActions({
      getLogSincronizacionGeclisaByParams:
        "expedientes/getLogSincronizacionGeclisaByParams",
      setAlert: "user/setAlert"
    }),
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
      this.nroExpedienteDisabled = false;
      this.fechasDisabled = false;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    async applyFilters() {
      this.loading = true;
      this.customizeFiltersApplied();
      const data = {
        expedienteId: this.nroExpediente,
        fechaDesde: this.fechaDesdeTextField,
        fechaHasta: this.fechaHastaTextField
      };
      try {
        const response = await this.getLogSincronizacionGeclisaByParams(data);
        this.resultsLogSincronizacionGeclisa = response;
        if (this.resultsLogSincronizacionGeclisa.length > 0) {
          this.showFilters = false;
          this.showFiltersSelected = true;
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];

      if (this.nroExpediente) {
        this.filtersApplyed.splice(0, 1, {
          key: "nroExpediente",
          label: "N° de expediente",
          model: this.nroExpediente
        });
      }
      if (this.fechaDesdeTextField) {
        this.filtersApplyed.splice(1, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeTextField
        });
      }
      if (this.fechaHastaTextField) {
        this.filtersApplyed.splice(2, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaTextField
        });
      }
    },
    // metodos date picker
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  }
};
</script>

<style></style>
